<template>
  <div>
    <div
      v-for="(infoCard, index) in infoCardsContent"
      :key="`${index}-${infoCard.id}`"
    >
      <info-card-right
        v-if="index % 2 == 0 || !infoCard.image"
        :info="infoCard"
        :colorSet="
          infoCardsContent.length % 3 == 2 ? index % 3 : (index + 1) % 3
        "
        :demo="demo"
        :showOptions="showOptions"
        :demoPreview="demoPreview"
        :previewImage="previewImage"
        @modifyContent="modifyContent"
        @deleteContent="deleteContent"
      />
      <info-card-left
        v-else
        :info="infoCard"
        :colorSet="
          infoCardsContent.length % 3 == 2 ? index % 3 : (index + 1) % 3
        "
        :demo="demo"
        :showOptions="showOptions"
        :demoPreview="demoPreview"
        :previewImage="previewImage"
        @modifyContent="modifyContent"
        @deleteContent="deleteContent"
      />
      <hr />
    </div>
  </div>
</template>

<script>
import InfoCardRight from "./InfoCardRight";
import InfoCardLeft from "./InfoCardLeft";
export default {
  name: "info-card",
  components: {
    "info-card-right": InfoCardRight,
    "info-card-left": InfoCardLeft
  },
  props: {
    infoCardsContent: {
      type: Array,
      required: true
    },

    demo: {
      type: Boolean,
      default: false
    },
    demoPreview: {
      type: Boolean,
      default: false
    },
    showOptions: {
      type: Boolean,
      required: false
    },
    previewImage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteContent(content) {
      this.$parent.$emit("deleteContent", content);
    },
    modifyContent(content) {
      this.$parent.$emit("modifyContent", content);
    }
  }
};
</script>

<style scoped></style>
