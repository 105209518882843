var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"content-color container-height py-7",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('h2',{staticClass:"text-center px-12 py-2 text-sm-h4 title-color fit-content-width",class:{ 'text-subtitle-1': _vm.demo }},[_vm._v(" TERAPIAS ")])])],1),_c('br'),_c('v-container',{class:{
      'px-1': _vm.$vuetify.breakpoint.lgAnUp,
      'px-16': _vm.$vuetify.breakpoint.xlOnly
    },attrs:{"fluid":!_vm.$vuetify.breakpoint.lgAndUp}},[_c('v-row',{class:{
        'px-3': _vm.$vuetify.breakpoint.mdAndUp,
        'px-16 mx-16': _vm.$vuetify.breakpoint.xlOnly
      }},_vm._l((_vm.content),function(therapy,index){return _c('v-col',{key:(index + "-" + (therapy.id)),class:_vm.$vuetify.breakpoint.lgAndDown ? 'mb-3' : 'mb-6',attrs:{"cols":"12","sm":_vm.demo ? '12' : _vm.content.length >= 2 ? '6' : '12',"md":_vm.demo
            ? _vm.content.length >= 2
              ? '6'
              : '12'
            : _vm.content.length >= 3
            ? '4'
            : _vm.content.length === 2
            ? '6'
            : '12',"lg":_vm.demo
            ? _vm.content.length >= 3
              ? '4'
              : _vm.content.length === 2
              ? '6'
              : '12'
            : _vm.content.length >= 3
            ? '4'
            : _vm.content.length === 2
            ? '6'
            : '12',"justify":"center","align":"center"}},[_c('v-lazy',{attrs:{"options":{ threshold: 0.5 },"transition":"slide-x-transition","min-height":"200","height":"100%"}},[_c('therapy-card',{attrs:{"showOptions":_vm.showOptions,"content":therapy,"dark":index % 2 == 1},on:{"goToTherapySection":_vm.goToTherapySection,"modifyContent":_vm.modifyContent,"deleteContent":_vm.deleteContent}})],1)],1)}),1)],1),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }