var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('h2',{staticClass:"text-center py-2 text-sm-h4 title-color-testimonials fit-content-width",class:{
          'text-subtitle-1': _vm.demo,
          'px-12': !_vm.demo || (_vm.demo && _vm.$vuetify.breakpoint.smAndUp),
          'px-3': _vm.demo && !_vm.$vuetify.breakpoint.smAndUp
        }},[_vm._v(" TESTIMONIOS ")])])],1),_c('br'),_c('v-carousel',{staticClass:"color-buttons",attrs:{"height":"fit-content","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.testimonialContents),function(testimonialContent,index){return _c('v-carousel-item',{key:(index + "-" + (testimonialContent.id)),staticClass:"content-relative"},[(_vm.showOptions)?_c('v-menu',{class:{
          'list-position-xlOnly': _vm.$vuetify.breakpoint.xlOnly,
          'list-position-lgAndUp': _vm.$vuetify.breakpoint.lg,
          'list-position-mdAndUp': _vm.$vuetify.breakpoint.md,
          'list-position-smAndUp': _vm.$vuetify.breakpoint.sm,
          'list-position-xs': _vm.$vuetify.breakpoint.xsOnly,
          'list-position-xxs':
            _vm.$vuetify.breakpoint.width <= 423 &&
            _vm.$vuetify.breakpoint.width > 378,
          'list-position-xxxs': _vm.$vuetify.breakpoint.width <= 378
        },attrs:{"attach":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"content-absolute button-primary",attrs:{"elevation":"7","fab":"","small":"","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.modifyContent(testimonialContent)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteContent(testimonialContent)}}},[_c('v-list-item-title',[_vm._v("Eliminar")])],1)],1)],1):_vm._e(),(testimonialContent.image && !_vm.demo)?_c('v-img',{attrs:{"src":testimonialContent.image,"lazy-src":testimonialContent.image,"max-height":"500","contain":""}}):_vm._e(),(testimonialContent.image && _vm.demo)?_c('v-container',{staticClass:"image-height"},[_c('v-img',{staticClass:"content-position fill-height",attrs:{"src":testimonialContent.image,"lazy-src":testimonialContent.image,"max-height":"500"}})],1):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"justify":"center","aling":"center"}},[_c('v-col',{attrs:{"justify":"center","align":"center"}},[(testimonialContent.video)?_c('iframe',{class:_vm.$vuetify.breakpoint.xlOnly
                  ? 'iframe-height-xl'
                  : _vm.$vuetify.breakpoint.lgAndUp
                  ? 'iframe-height-lg'
                  : _vm.$vuetify.breakpoint.mdAndUp
                  ? 'iframe-height-md'
                  : 'iframe-height-sm',attrs:{"src":testimonialContent.video,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e()])],1)],1),_c('br'),_c('div',{staticClass:"text-center text-sm-h5 subtitle-color fill-height subtitle-break-word mb-12 white-space-break-spaces font-weight-light"},[_vm._v(" "+_vm._s(testimonialContent.content)+" ")])],1)}),1),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }