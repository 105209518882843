<template>
  <website-builder />
</template>

<script>
import WebsiteBuilder from "@/components/websiteBuilder/WebsiteBuilder.vue";

export default {
  name: "website-view",
  components: {
    "website-builder": WebsiteBuilder
  },
  data() {
    return {};
  }
};
</script>

<style></style>
