var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"content-relative",class:{
    'background-clean':
      _vm.colorSet == _vm.setOfColors.PRIMARY || _vm.colorSet == _vm.setOfColors.CLEAN,
    'background-secondary': _vm.colorSet == _vm.setOfColors.SECONDARY
  },attrs:{"fluid":""}},[(_vm.showOptions)?_c('v-menu',{class:{
      'list-position-xlOnly': _vm.$vuetify.breakpoint.xlOnly,
      'list-position-lgAndUp': _vm.$vuetify.breakpoint.lg,
      'list-position-mdAndUp': _vm.$vuetify.breakpoint.md,
      'list-position-smAndUp': _vm.$vuetify.breakpoint.sm,
      'list-position-xs': _vm.$vuetify.breakpoint.xsOnly,
      'list-position-xxs':
        _vm.$vuetify.breakpoint.width <= 423 && _vm.$vuetify.breakpoint.width > 378,
      'list-position-xxxs': _vm.$vuetify.breakpoint.width <= 378
    },attrs:{"attach":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"content-absolute",class:{
          'title-clean':
            _vm.colorSet == _vm.setOfColors.PRIMARY || _vm.colorSet == _vm.setOfColors.CLEAN,
          'title-secondary': _vm.colorSet == _vm.setOfColors.SECONDARY
        },attrs:{"elevation":"7","fab":"","small":"","drak":_vm.colorSet == _vm.setOfColors.CLEAN,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.colorSet == _vm.setOfColors.PRIMARY
              ? 'primary'
              : _vm.colorSet == _vm.setOfColors.SECONDARY
              ? 'white'
              : 'primary'}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1909612616)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.modifyContent(_vm.info)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('v-lazy',{attrs:{"options":{ threshold: 0.5 },"transition":"slide-x-transition","min-height":"55"}},[_c('h2',{staticClass:"text-center px-12 py-2 text-sm-h4 fit-content-width white-space-break-spaces",class:{
            'title-clean':
              _vm.colorSet == _vm.setOfColors.PRIMARY ||
              _vm.colorSet == _vm.setOfColors.CLEAN,
            'title-secondary': _vm.colorSet == _vm.setOfColors.SECONDARY,
            'text-subtitle-1': _vm.demo
          }},[_vm._v(" "+_vm._s(_vm.info.title)+" ")])])],1)],1),_c('v-row',{class:_vm.$vuetify.breakpoint.smAndUp ? 'px-10' : '',attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[(_vm.info.image)?_c('v-img',{staticClass:"info-card-image-background",attrs:{"max-height":"600","max-width":"600","src":_vm.imageFile,"lazy-src":_vm.imageFile}}):_vm._e()],1),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndUp ? 'px-10' : '',attrs:{"cols":"12","justify":"center","align":"start"}},[_c('v-lazy',{attrs:{"options":{ threshold: 0.5 },"transition":"slide-x-transition","min-height":"200"}},[_c('v-row',{class:_vm.$vuetify.breakpoint.lgAndUp ? 'px-12' : ''},[_c('v-col',{class:_vm.$vuetify.breakpoint.lgAndUp ? 'px-12' : '',attrs:{"cols":"12","justify":"center"}},[_c('p',{staticClass:"text-md-h6 text-sm-subtitle-1 white-space-break-spaces font-weight-light text-justify"},[_vm._v(" "+_vm._s(_vm.info.content)+" ")])])],1)],1)],1)],1),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }