<template>
  <v-dialog
    v-model="showModal"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-toolbar dark color="primary" class="toolbar">
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ titleToShow }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="saveComponent" :loading="loading"
            >Guardar</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-container class="mt-7">
          <v-form ref="component">
            <v-row>
              <v-col>
                <slot
                  v-bind:contents="contents"
                  v-bind:previewImage="imageURL"
                  v-bind:demo="false"
                ></slot>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="title.visible"
                cols="12"
                :sm="contentToModify ? '6' : '12'"
              >
                <h1 class="mb-5 text-sm-h4 text-h6 title-primary">
                  Título del componente
                </h1>
                <v-text-field
                  v-model.trim="content.title"
                  :rules="
                    title.required ? [rules.required, rules.requiredText] : []
                  "
                  prepend-inner-icon="mdi-format-title"
                  label="Título"
                  maxlength="300"
                  counter
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="contentToModify">
                <h1 class="mb-5 text-sm-h4 text-h6 title-primary">Posición</h1>
                <v-select
                  v-model="content.position"
                  :items="positions"
                  prepend-inner-icon="mdi-order-numeric-ascending"
                  label="Orden de aparición"
                  outlined
                  attach
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="body.visible">
              <v-col>
                <h1 class="mb-5 text-sm-h4 text-h6 title-primary">
                  Contenido del componente
                </h1>
                <v-textarea
                  v-model.trim="content.content"
                  :rules="
                    body.required ? [rules.required, rules.requiredText] : []
                  "
                  prepend-inner-icon="mdi-subtitles"
                  label="Contenido"
                  maxlength="5000"
                  counter
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="multimedia">
            <v-row class="pb-0" v-if="image.visible && video.visible">
              <v-col class="pb-0">
                <h1 class="mb-5 text-sm-h4 text-h6 title-primary">
                  Contenido multimedia
                </h1>
              </v-col>
            </v-row>
            <v-row v-if="image.visible && video.visible">
              <v-col cols="6" sm="2">
                <v-checkbox
                  v-model="imageSelected"
                  label="Imagen"
                  value
                ></v-checkbox>
              </v-col>
              <v-col cols="6" sm="2">
                <v-checkbox
                  v-model="videoSelected"
                  label="Video"
                  value
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="imageSelected && image.visible">
              <v-col>
                <h1 class="mb-5 text-sm-h5 text-h6 title-primary">Imagen</h1>
                <v-file-input
                  v-model="content.image"
                  :rules="
                    image.required
                      ? [rules.required]
                      : imageOrVideo
                      ? [rules.imageOrVideo]
                      : []
                  "
                  chips
                  show-size
                  outlined
                  hide-hint
                  accept="image/*"
                  placeholder="Seleccione un archivo"
                  class="scrollX chip-container scroll-style scroll-container"
                  @change="previewImage"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip>{{ text }}</v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row v-if="videoSelected && video.visible">
              <v-col>
                <h1 class="mb-5 text-sm-h5 text-h6 title-primary">Video</h1>
                <v-text-field
                  v-model.trim="content.video"
                  :rules="
                    video.required
                      ? [rules.required, rules.requiredText, rules.youtubeURL]
                      : imageOrVideo
                      ? [
                          rules.imageOrVideo,
                          rules.requiredText,
                          rules.youtubeURL
                        ]
                      : [rules.requiredText, rules.youtubeURL]
                  "
                  prepend-inner-icon="mdi-video"
                  class="border"
                  label="URL del video"
                  persistent-hint
                  hint="Debe ser un URL válido de un video de YouTube"
                  maxlength="50000"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <success-message
        :showModal="showSuccessMessage"
        :title="successTitle"
        :message="successMessage"
        @okAction="successfulTransaction()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import SuccessMessage from "@/components/common/notifications/SuccessMessage";

import Repository from "@/services/repositories/repositoryFactory";
const WebsiteRepository = Repository.get("website");

import { THERAPIST } from "@/config/constants";

export default {
  name: "builder-modal",
  components: {
    "success-message": SuccessMessage
  },
  props: {
    showModal: { type: Boolean, required: true },
    title: {
      type: Object,
      default: () => {
        return { required: true, visible: true };
      }
    },
    body: {
      type: Object,
      default: () => {
        return { required: true, visible: true };
      }
    },
    image: {
      type: Object,
      default: () => {
        return { required: true, visible: true };
      }
    },
    video: {
      type: Object,
      default: () => {
        return { required: true, visible: true };
      }
    },
    section: {
      type: Object,
      required: true
    },
    contentToModify: {
      type: Object,
      default: null
    },
    imageOrVideo: {
      type: Boolean,
      default: false
    },
    titleToShow: { type: String, required: true },
    websiteContents: { type: Array, default: null }
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Campo Obligatorio",
        requiredText: value =>
          /\S/.test(value) || "No se admiten campos en blanco",
        youtubeURL: value =>
          (value
            ? value.includes("www.youtube.com")
            : "".includes("www.youtube.com")) ||
          "El URL debe incluir 'www.youtube.com'",
        imageOrVideo: () =>
          !!this.content.image ||
          !!this.content.video ||
          "Debe indicar una imagen o video como contenido multimedia"
      },
      content: {
        title: null,
        content: null,
        image: null,
        position: null,
        video: null
      },
      loading: false,
      showSuccessMessage: false,
      successTitle: "",
      successMessage: "",
      imageURL: null,
      imageChanged: false,
      imageSelected: false,
      videoSelected: false
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", false);
    },
    loadComponents() {
      this.$emit("loadComponents");
    },
    successfulTransaction() {
      this.loadComponents();
      this.showSuccessMessage = false;
      this.closeModal();
    },
    validateForm() {
      let isContentValid = this.$refs.component.validate();
      let isMultimediaValid = this.$refs.multimedia.validate();
      return isContentValid && isMultimediaValid;
    },
    previewImage() {
      this.imageChanged = true;
      if (this.content.image) {
        this.imageURL = URL.createObjectURL(this.content.image);
      } else {
        this.imageURL = "";
      }
    },
    renameFile(file, id) {
      let newFile = new FormData();
      let filename = this.section.name.replaceAll(" ", "_");
      filename +=
        "-" +
        Date.now() +
        "WebsiteContent_" +
        id +
        "." +
        this.content.image.name.substring(
          this.content.image.name.lastIndexOf(".") + 1,
          this.content.image.name.length
        );

      newFile.append("image", this.content.image, filename);
      return newFile;
    },
    createdSuccessfully() {
      this.successTitle = "¡Se ha creado el componente exitosamente!";
      this.successMessage =
        "El componente se ha registrado y podrá ser visualizado en su página web";
      this.showSuccessMessage = true;
    },
    updatedSuccessfully() {
      this.successTitle = "¡Se ha actualizado el componente exitosamente!";
      this.successMessage =
        "Los cambios en el componente podrán ser visualizados en su página web";
      this.showSuccessMessage = true;
    },
    async createComponent() {
      this.loading = true;

      let contentToCreate = {
        ...this.content,
        image: null,
        therapist: {
          id: THERAPIST.MAIN.id
        },
        position: this.websiteContents.length + 1,
        websiteSection: {
          id: this.section.id
        }
      };

      await WebsiteRepository.createContent(contentToCreate)
        .then(async res => {
          if (this.content.image) {
            let fileToSave = this.renameFile(this.content.image, res.id);
            await WebsiteRepository.uploadFile(fileToSave).then(() => {
              this.createdSuccessfully();
            });
          } else {
            this.createdSuccessfully();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateComponent() {
      this.loading = true;
      let contentToUpdate = {
        ...this.content,
        image: this.content.image
          ? this.imageChanged
            ? null
            : this.contentToModify.image
          : null,
        status: {
          id: this.content.status
        }
      };
      let filenameToDelete =
        this.contentToModify.image && !contentToUpdate.image
          ? this.contentToModify.image
          : null;
      let arrayOfContents = this.websiteContents.filter(
        websiteContent => websiteContent.id != contentToUpdate.id
      );
      let position = 0;
      let websiteContentsToUpdate = [];
      arrayOfContents.forEach(websiteContent => {
        position++;

        if (position === contentToUpdate.position) {
          websiteContentsToUpdate.push(contentToUpdate);
          position++;
        }

        let auxContent = {
          ...websiteContent,
          position: position,
          status: {
            id: websiteContent.status
          }
        };

        websiteContentsToUpdate.push({ ...auxContent });
      });
      if (position !== this.websiteContents.length) {
        websiteContentsToUpdate.push(contentToUpdate);
      }
      let sectionToUpdate = {
        id: this.section.id,
        name: this.section.name,
        websiteContents: websiteContentsToUpdate
      };
      await WebsiteRepository.updateSection(sectionToUpdate)
        .then(async () => {
          if (this.content.image && this.imageChanged) {
            let fileToSave = this.renameFile(
              this.content.image,
              this.content.id
            );

            await WebsiteRepository.uploadFile(fileToSave);

            if (filenameToDelete) {
              await WebsiteRepository.deleteFile(filenameToDelete);
            }
            this.updatedSuccessfully();
          } else {
            if (filenameToDelete) {
              await WebsiteRepository.deleteFile(filenameToDelete);
            }
            this.updatedSuccessfully();
          }
        })
        .finally(() => {
          this.loading = false;
        });
      this.loading = false;
    },
    async saveComponent() {
      if (this.validateForm()) {
        if (!this.contentToModify) {
          this.createComponent();
        } else {
          this.updateComponent();
        }
      }
    }
  },
  computed: {
    contents() {
      return [this.content];
    },
    positions() {
      return Array.from(
        { length: this.websiteContents.length },
        (x, y) => y + 1
      );
    }
  },
  watch: {
    imageSelected(newValue) {
      if (newValue) {
        this.videoSelected = false;
        this.content.video = null;
      } else {
        this.content.image = null;
        if (this.imageOrVideo) {
          this.videoSelected = true;
        }
      }
      this.$refs.multimedia.resetValidation();
    },
    videoSelected(newValue) {
      if (newValue) {
        this.imageSelected = false;
        this.content.image = null;
      } else {
        this.content.video = null;
        if (this.imageOrVideo) {
          this.imageSelected = true;
        }
      }
      this.$refs.multimedia.resetValidation();
    }
  },
  mounted() {
    if (this.contentToModify) {
      if (this.contentToModify.image) {
        this.imageSelected = true;
        this.imageURL = `${WebsiteRepository.getRoute()}/${
          this.contentToModify.image
        }`;
        let fileImage = new File([""], this.contentToModify.image);
        this.content = { ...this.contentToModify, image: fileImage };
      } else {
        if (this.contentToModify.video) {
          this.videoSelected = true;
        } else {
          this.imageSelected = true;
        }
        this.content = Object.assign({}, this.contentToModify);
      }
    } else {
      this.imageSelected = true;
    }
  }
};
</script>

<style scoped>
.title-primary {
  color: var(--v-primary-base);
}
.toolbar {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
}
.scrollX ::v-deep .v-text-field__slot {
  overflow-x: scroll !important;
}
.scroll-style ::v-deep .v-text-field__slot::-webkit-scrollbar {
  height: 6px !important;
  border-radius: 10px !important;
  background-color: rgba(168, 168, 168, 0.3);
}
.scroll-style ::v-deep .v-text-field__slot::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.3);
  border: solid 1px rgba(255, 255, 255, 0.4);
}
.scroll-style ::v-deep .v-text-field__slot::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  background-color: rgba(255, 255, 255, 0.4);
}
.chip-container ::v-deep .v-file-input__text {
  min-width: fit-content;
}
.scroll-container ::v-deep .v-input__slot {
  padding-bottom: 3px !important;
  cursor: pointer !important;
}
</style>
