<template>
  <v-container fluid class="content-color container-height py-7">
    <v-row>
      <v-col cols="12" justify="center" align="center">
        <h2
          class="text-center px-12 py-2 text-sm-h4 title-color fit-content-width"
          :class="{ 'text-subtitle-1': demo }"
        >
          TERAPIAS
        </h2>
      </v-col>
    </v-row>
    <br />
    <v-container
      :fluid="!$vuetify.breakpoint.lgAndUp"
      :class="{
        'px-1': $vuetify.breakpoint.lgAnUp,
        'px-16': $vuetify.breakpoint.xlOnly
      }"
    >
      <v-row
        :class="{
          'px-3': $vuetify.breakpoint.mdAndUp,
          'px-16 mx-16': $vuetify.breakpoint.xlOnly
        }"
      >
        <v-col
          cols="12"
          :sm="demo ? '12' : content.length >= 2 ? '6' : '12'"
          :md="
            demo
              ? content.length >= 2
                ? '6'
                : '12'
              : content.length >= 3
              ? '4'
              : content.length === 2
              ? '6'
              : '12'
          "
          :lg="
            demo
              ? content.length >= 3
                ? '4'
                : content.length === 2
                ? '6'
                : '12'
              : content.length >= 3
              ? '4'
              : content.length === 2
              ? '6'
              : '12'
          "
          justify="center"
          align="center"
          :class="$vuetify.breakpoint.lgAndDown ? 'mb-3' : 'mb-6'"
          v-for="(therapy, index) in content"
          :key="`${index}-${therapy.id}`"
        >
          <v-lazy
            :options="{ threshold: 0.5 }"
            transition="slide-x-transition"
            min-height="200"
            height="100%"
          >
            <therapy-card
              :showOptions="showOptions"
              :content="therapy"
              :dark="index % 2 == 1"
              @goToTherapySection="goToTherapySection"
              @modifyContent="modifyContent"
              @deleteContent="deleteContent"
            />
          </v-lazy>
        </v-col>
      </v-row>
    </v-container>
    <br />
  </v-container>
</template>

<script>
import TherapyCard from "./TherapyCard";

export default {
  name: "therapies-summary",
  props: {
    content: {
      type: Array,
      required: true
    },
    showOptions: {
      type: Boolean,
      required: false
    },
    demo: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "therapy-card": TherapyCard
  },
  data() {
    return {};
  },
  methods: {
    deleteContent(content) {
      this.$parent.$emit("deleteContent", content);
    },
    modifyContent(content) {
      this.$parent.$emit("modifyContent", content);
    },
    goToTherapySection() {
      this.$emit("goToTherapySection");
    }
  }
};
</script>

<style scoped>
.title-color {
  border: solid 2px var(--v-primary-base);
  color: var(--v-primary-base);
}
.button-text-color {
  color: white;
}
.fit-content-width {
  width: fit-content;
}
.container-height {
  min-height: 450px;
}
.shadow {
  -webkit-filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.3));
}
</style>
