<template>
  <v-container class="py-7">
    <v-row>
      <v-col cols="12" justify="center" align="center">
        <h2
          :class="{
            'text-subtitle-1': demo,
            'px-12': !demo || (demo && $vuetify.breakpoint.smAndUp),
            'px-3': demo && !$vuetify.breakpoint.smAndUp
          }"
          class="text-center py-2 text-sm-h4 title-color-testimonials fit-content-width"
        >
          TESTIMONIOS
        </h2>
      </v-col>
    </v-row>
    <br />
    <v-carousel
      height="fit-content"
      hide-delimiter-background
      class="color-buttons"
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(testimonialContent, index) in testimonialContents"
        :key="`${index}-${testimonialContent.id}`"
        class="content-relative"
      >
        <v-menu
          v-if="showOptions"
          attach
          offset-y
          :class="{
            'list-position-xlOnly': $vuetify.breakpoint.xlOnly,
            'list-position-lgAndUp': $vuetify.breakpoint.lg,
            'list-position-mdAndUp': $vuetify.breakpoint.md,
            'list-position-smAndUp': $vuetify.breakpoint.sm,
            'list-position-xs': $vuetify.breakpoint.xsOnly,
            'list-position-xxs':
              $vuetify.breakpoint.width <= 423 &&
              $vuetify.breakpoint.width > 378,
            'list-position-xxxs': $vuetify.breakpoint.width <= 378
          }"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="7"
              fab
              small
              dark
              v-bind="attrs"
              v-on="on"
              icon
              class="content-absolute button-primary"
            >
              <v-icon color="primary">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="modifyContent(testimonialContent)">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteContent(testimonialContent)">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-img
          v-if="testimonialContent.image && !demo"
          :src="testimonialContent.image"
          :lazy-src="testimonialContent.image"
          max-height="500"
          contain
        ></v-img>
        <v-container
          class="image-height"
          v-if="testimonialContent.image && demo"
        >
          <v-img
            class="content-position fill-height"
            :src="testimonialContent.image"
            :lazy-src="testimonialContent.image"
            max-height="500"
          ></v-img>
        </v-container>
        <v-container>
          <v-row justify="center" aling="center">
            <v-col justify="center" align="center">
              <iframe
                v-if="testimonialContent.video"
                :class="
                  $vuetify.breakpoint.xlOnly
                    ? 'iframe-height-xl'
                    : $vuetify.breakpoint.lgAndUp
                    ? 'iframe-height-lg'
                    : $vuetify.breakpoint.mdAndUp
                    ? 'iframe-height-md'
                    : 'iframe-height-sm'
                "
                :src="testimonialContent.video"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <div
          class="text-center text-sm-h5 subtitle-color fill-height subtitle-break-word mb-12 white-space-break-spaces font-weight-light"
        >
          {{ testimonialContent.content }}
        </div>
      </v-carousel-item>
    </v-carousel>
    <br />
  </v-container>
</template>

<script>
import Repository from "@/services/repositories/repositoryFactory";
const WebsiteRepository = Repository.get("website");

export default {
  name: "testimonials",
  props: {
    content: {
      type: Array,
      required: true
    },
    demo: {
      type: Boolean,
      required: false
    },
    showOptions: {
      type: Boolean,
      required: false
    },
    previewImage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteContent(content) {
      let contentToSend = this.content.find(testimonialContent => {
        return testimonialContent.id === content.id;
      });
      this.$parent.$emit("deleteContent", contentToSend);
    },
    modifyContent(content) {
      let contentToSend = this.content.find(testimonialContent => {
        return testimonialContent.id === content.id;
      });
      this.$parent.$emit("modifyContent", contentToSend);
    }
  },
  computed: {
    testimonialContents() {
      let contents = [];
      this.content.forEach(testimonialContent => {
        let transformedContent = Object.assign({}, testimonialContent);
        if (transformedContent.image) {
          if (this.previewImage) {
            transformedContent.image = this.previewImage;
          } else {
            transformedContent.image = `${WebsiteRepository.getRoute()}/${
              transformedContent.image
            }`;
          }
        }

        contents.push(transformedContent);
      });
      return contents;
    }
  }
};
</script>

<style scoped>
.title-color-testimonials {
  border: solid 2px var(--v-primary-base);
  color: var(--v-primary-base);
}
.button-primary {
  border: solid 2px var(--v-primary-base);
  color: var(--v-primary-base);
  background-color: white !important;
}
.color-buttons ::v-deep .v-carousel__controls__item {
  color: var(--v-primary-base) !important;
}
.fit-content-width {
  width: fit-content;
}
.content-position {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.content-absolute {
  position: absolute;
  margin: 8px;
  background-color: var(--v-primary-base);
  z-index: 2;
  top: 0;
  right: 0;
}
.content-relative {
  position: relative;
}
.subtitle-break-word {
  word-break: break-word;
}
.image-height {
  height: 500px;
}
.subtitle-color {
  background-color: white;
  color: var(--v-primary-base);
}
.iframe-height-xl {
  width: 60%;
  height: 500px;
}
.iframe-height-lg {
  width: 90%;
  height: 500px;
}

.iframe-height-md {
  width: 75%;
  height: 400px;
}
.iframe-height-sm {
  width: 100%;
  height: 300px;
}
.white-space-break-spaces {
  white-space: break-spaces;
}
.list-position-xlOnly ::v-deep .v-menu__content {
  left: 91% !important;
}
.list-position-lgAndUp ::v-deep .v-menu__content {
  left: 89% !important;
}
.list-position-mdAndUp ::v-deep .v-menu__content {
  left: 86% !important;
}
.list-position-smAndUp ::v-deep .v-menu__content {
  left: 81% !important;
}
.list-position-xs ::v-deep .v-menu__content {
  left: 65% !important;
  top: 5% !important;
}
.list-position-xxs ::v-deep .v-menu__content {
  left: 60% !important;
  top: 5% !important;
}
.list-position-xxxs ::v-deep .v-menu__content {
  left: 30% !important;
  top: 5% !important;
}
</style>
