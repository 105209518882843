<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="8">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
      <v-col
        class="d-flex align-start"
        :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : ' justify-center'"
        cols="12"
        sm="4"
      >
        <v-skeleton-loader type="chip"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <br />
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "website-section-loader",
  data() {
    return {};
  }
};
</script>

<style scoped></style>
