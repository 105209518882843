<template>
  <v-container class="pa-6">
    <website-content-loader v-if="loadingComponent" />
    <error-view v-else-if="errorLoading" />
    <template v-else>
      <v-container>
        <v-row class="pb-0">
          <v-col
            cols="12"
            md="9"
            :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
            class="pb-0"
          >
            <h2 class="title-primary text-sm-h4">
              Visualización del componente
            </h2>
          </v-col>
          <v-col
            cols="12"
            md="3"
            :align="$vuetify.breakpoint.xsOnly ? 'center' : 'end'"
            class="pb-0"
          >
            <v-row class="pb-0">
              <v-col
                cols="12"
                v-if="websiteSection.create"
                :align="$vuetify.breakpoint.xsOnly ? 'center' : 'end'"
                class="pb-0"
              >
                <v-btn outlined color="primary" @click="createNewContent"
                  >Añadir nuevo</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <hr />
      <br />

      <empty-section
        title="¡Este componente no posee contenido!"
        subtitle="Registre un contenido para visualizarlo."
        icon="mdi-application-cog"
        v-if="noContent"
      />
      <v-container
        v-else
        :class="
          websiteBuilderSections.TESTIMONIALS.id === section.id ||
          websiteBuilderSections.THERAPIES_SUMMARIES.id === section.id
            ? 'shadow-alt position-relative'
            : 'shadow position-relative'
        "
      >
        <slot
          v-bind:contents="contents"
          v-bind:showOptions="true"
          v-bind:previewImage="''"
          v-bind:demo="true"
        ></slot>
      </v-container>
      <br />
      <br />

      <builder-modal
        v-if="showModal"
        :showModal="showModal"
        :titleToShow="modalTitle"
        :title="websiteSection.title"
        :body="websiteSection.content"
        :image="websiteSection.image"
        :video="websiteSection.video"
        :imageOrVideo="websiteSection.imageOrVideo"
        :section="section"
        :contentToModify="contentToModify"
        :websiteContents="contents"
        @closeModal="openModal"
        @loadComponents="loadSection"
      >
        <template v-slot:default="websiteContent">
          <slot
            v-bind:contents="websiteContent.contents"
            v-bind:showOptions="false"
            v-bind:previewImage="websiteContent.previewImage"
            v-bind:demo="websiteContent.demo"
          ></slot>
        </template>
      </builder-modal>
      <warning-message
        :loading="loadingDeletion"
        :showModal="showWarningMessage"
        ok="Eliminar"
        title="¿Desea eliminar este componente?"
        message="El componente desaparecerá de su página web. Esta acción no se podrá revertir"
        @cancelAction="openWarningMessage"
        @okAction="deleteContent"
      />
      <success-message
        :showModal="showSuccessMessage"
        title="¡Se ha eliminado el componente exitosamente!"
        message="El componente se ha eliminado y ya no será visualizado en su página web"
        @okAction="successfulTransaction()"
      />
    </template>
  </v-container>
</template>

<script>
import WarningMessage from "@/components/common/notifications/WarningMessage";
import SuccessMessage from "@/components/common/notifications/SuccessMessage";
import WebsiteContentLoader from "@/components/common/loaders/WebsiteContentLoader";
import ErrorView from "@/components/common/notifications/ErrorView";
import EmptySection from "@/components/common/notifications/EmptySection";
import BuilderModal from "./BuilderModal";

import Repository from "@/services/repositories/repositoryFactory";
const WebsiteRepository = Repository.get("website");

import { WEBSITE_BUILDER_SECTIONS } from "@/config/constants";

export default {
  name: "builder-template",
  components: {
    "builder-modal": BuilderModal,
    "warning-message": WarningMessage,
    "success-message": SuccessMessage,
    "website-content-loader": WebsiteContentLoader,
    "error-view": ErrorView,
    "empty-section": EmptySection
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      contents: [],
      showModal: false,
      modalTitle: "",
      showWarningMessage: false,
      showSuccessMessage: false,
      loadingDeletion: false,
      contentToDelete: null,
      contentToModify: null,
      loadingComponent: false,
      errorLoading: false,
      websiteBuilderSections: WEBSITE_BUILDER_SECTIONS,
      noContent: false
    };
  },
  methods: {
    async loadSection() {
      this.loadingComponent = true;
      this.contents = [];
      let websiteSection = await WebsiteRepository.getWebsiteSectionById(
        this.section.id
      )
        .catch(() => {
          this.errorLoading = true;
        })
        .finally(() => {
          this.loadingComponent = false;
        });
      if (websiteSection?.websiteContents) {
        this.contents = websiteSection.websiteContents;
        this.noContent = false;
      } else {
        this.noContent = true;
      }
    },
    openModal(visibility) {
      this.showModal = visibility;
    },
    openWarningMessage(visibility) {
      this.showWarningMessage = visibility;
    },
    openSuccessMessage(visibility) {
      this.showSuccessMessage = visibility;
    },
    successfulTransaction() {
      this.openSuccessMessage(false);
      this.loadSection();
    },
    createNewContent() {
      this.contentToModify = null;
      this.modalTitle = `Crear nuevo componente - ${this.section.name}`;
      this.openModal(true);
    },
    async deleteContent() {
      this.loadingDeletion = true;

      await WebsiteRepository.deleteContent(this.contentToDelete.id)
        .then(() => {
          this.openSuccessMessage(true);
        })
        .finally(() => {
          this.loadingDeletion = false;
          this.openWarningMessage(false);
          this.contentToDelete = null;
        });
    }
  },
  computed: {
    websiteSection() {
      for (const builderSection in WEBSITE_BUILDER_SECTIONS) {
        if (WEBSITE_BUILDER_SECTIONS[builderSection].id === this.section.id) {
          return WEBSITE_BUILDER_SECTIONS[builderSection];
        }
      }
      return null;
    }
  },
  mounted() {
    this.loadSection();
  },
  created() {
    this.$on("deleteContent", function(content) {
      this.openWarningMessage(true);
      this.contentToDelete = content;
    });
    this.$on("modifyContent", function(content) {
      this.contentToModify = content;
      this.modalTitle = `Editar componente - ${this.section.name}`;
      this.openModal(true);
    });
  }
};
</script>

<style scoped>
.title-primary {
  color: var(--v-primary-base);
}
.position-relative {
  position: relative;
}
.shadow {
  -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
}
.shadow-alt {
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}
.white-space-break-spaces {
  white-space: break-spaces;
  word-break: break-word;
}
</style>
