<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2">
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-skeleton-loader tile type="image"></v-skeleton-loader>
          <v-skeleton-loader tile type="image"></v-skeleton-loader>
          <v-skeleton-loader tile type="image"></v-skeleton-loader>
          <v-skeleton-loader tile type="image"></v-skeleton-loader>
        </template>
        <template v-else>
          <v-skeleton-loader
            tile
            type="image"
            class="loader-height"
          ></v-skeleton-loader>
        </template>
      </v-col>
      <v-col cols="12" md="10">
        <website-content-loader />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WebsiteContentLoader from "./WebsiteContentLoader.vue";

export default {
  name: "website-section-loader",
  components: {
    "website-content-loader": WebsiteContentLoader
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.loader-height {
  height: 50px;
}
</style>
