<template>
  <v-container
    fluid
    :class="{
      'background-clean':
        colorSet == setOfColors.PRIMARY || colorSet == setOfColors.CLEAN,
      'background-secondary': colorSet == setOfColors.SECONDARY
    }"
    class="content-relative"
  >
    <v-menu
      attach
      v-if="showOptions"
      offset-y
      :class="{
        'list-position-xlOnly': $vuetify.breakpoint.xlOnly,
        'list-position-lgAndUp': $vuetify.breakpoint.lg,
        'list-position-mdAndUp': $vuetify.breakpoint.md,
        'list-position-smAndUp': $vuetify.breakpoint.sm,
        'list-position-xs': $vuetify.breakpoint.xsOnly,
        'list-position-xxs':
          $vuetify.breakpoint.width <= 423 && $vuetify.breakpoint.width > 378,
        'list-position-xxxs': $vuetify.breakpoint.width <= 378
      }"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          elevation="7"
          fab
          small
          :drak="colorSet == setOfColors.CLEAN"
          v-bind="attrs"
          v-on="on"
          icon
          class="content-absolute"
          :class="{
            'title-clean':
              colorSet == setOfColors.PRIMARY || colorSet == setOfColors.CLEAN,
            'title-secondary': colorSet == setOfColors.SECONDARY
          }"
        >
          <v-icon
            :color="
              colorSet == setOfColors.PRIMARY
                ? 'primary'
                : colorSet == setOfColors.SECONDARY
                ? 'white'
                : 'primary'
            "
            >mdi-dots-vertical</v-icon
          >
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="modifyContent(info)">
          <v-list-item-title>Editar</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-row justify="center" align="center">
      <v-col cols="12" justify="center" align="center">
        <v-lazy
          :options="{ threshold: 0.5 }"
          transition="slide-x-transition"
          min-height="55"
        >
          <h2
            class="text-center px-12 py-2 text-sm-h4 fit-content-width white-space-break-spaces"
            :class="{
              'title-clean':
                colorSet == setOfColors.PRIMARY ||
                colorSet == setOfColors.CLEAN,
              'title-secondary': colorSet == setOfColors.SECONDARY,
              'text-subtitle-1': demo
            }"
          >
            {{ info.title }}
          </h2>
        </v-lazy>
      </v-col>
    </v-row>
    <v-row
      :class="$vuetify.breakpoint.smAndUp ? 'px-10' : ''"
      justify="center"
      align="center"
    >
      <v-col cols="12" justify="center" align="center">
        <v-img
          v-if="info.image"
          max-height="600"
          max-width="600"
          class="info-card-image-background"
          :src="imageFile"
          :lazy-src="imageFile"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        justify="center"
        align="start"
        :class="$vuetify.breakpoint.smAndUp ? 'px-10' : ''"
      >
        <v-lazy
          :options="{ threshold: 0.5 }"
          transition="slide-x-transition"
          min-height="200"
        >
          <v-row :class="$vuetify.breakpoint.lgAndUp ? 'px-12' : ''">
            <v-col
              cols="12"
              justify="center"
              :class="$vuetify.breakpoint.lgAndUp ? 'px-12' : ''"
            >
              <p
                class="text-md-h6 text-sm-subtitle-1 white-space-break-spaces font-weight-light text-justify"
              >
                {{ info.content }}
              </p>
            </v-col>
          </v-row>
        </v-lazy>
      </v-col>
    </v-row>
    <br />
  </v-container>
</template>

<script>
import Repository from "@/services/repositories/repositoryFactory";
const WebsiteRepository = Repository.get("website");

import { WEBSITE_COLOR_SET } from "@/config/constants";

export default {
  name: "contact-card",
  props: {
    info: {
      type: Object,
      required: true
    },
    colorSet: {
      type: Number,
      required: true
    },
    showOptions: {
      type: Boolean,
      required: false
    },
    demo: {
      type: Boolean,
      required: false
    },
    previewImage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      setOfColors: WEBSITE_COLOR_SET
    };
  },
  methods: {
    modifyContent(content) {
      this.$parent.$emit("modifyContent", content);
    }
  },
  computed: {
    imageFile() {
      let image = this.info.image;
      if (image) {
        if (this.previewImage) {
          image = this.previewImage;
        } else {
          image = `${WebsiteRepository.getRoute()}/${image}`;
        }
        return image;
      } else {
        return image;
      }
    }
  }
};
</script>

<style scoped>
.fit-content-width {
  width: fit-content;
}
.content-absolute {
  position: absolute;
  z-index: 2;
  top: 0;
  margin: 8px;
  right: 0;
}
.text-justify {
  text-align: justify;
}
.content-relative {
  position: relative;
}
.background-primary {
  background-color: var(--v-primary-base);
  color: white;
}
.background-secondary {
  background-color: var(--v-secondary-base);
  color: white;
}
.background-clean {
  background-color: white;
  color: var(--v-primary-base);
}
.title-primary {
  background-color: white;
  color: var(--v-primary-base);
}
.title-secondary {
  border: solid 2px white;
  color: white;
}
.title-clean {
  border: solid 2px var(--v-primary-base);
  color: var(--v-primary-base);
}
.info-card-image-background {
  -webkit-box-shadow: 10px 10px 22px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 22px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 22px -10px rgba(0, 0, 0, 0.75);
}
.white-space-break-spaces {
  white-space: break-spaces;
  word-break: break-word;
}
.list-position-xlOnly ::v-deep .v-menu__content {
  left: 91% !important;
}
.list-position-lgAndUp ::v-deep .v-menu__content {
  left: 89% !important;
}
.list-position-mdAndUp ::v-deep .v-menu__content {
  left: 86% !important;
}
.list-position-smAndUp ::v-deep .v-menu__content {
  left: 81% !important;
}
.list-position-xs ::v-deep .v-menu__content {
  left: 65% !important;
}
.list-position-xxs ::v-deep .v-menu__content {
  left: 60% !important;
}
.list-position-xxxs ::v-deep .v-menu__content {
  left: 30% !important;
}
</style>
