<template>
  <v-card
    :class="dark ? 'card-color-dark' : 'card-color'"
    min-height="325px"
    width="350px"
    height="100%"
    elevation="10"
    class="align-card-height card-efect"
    @click="goToTherapySection"
  >
    <v-container class="content-relative">
      <v-menu v-if="showOptions" attach offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="dark ? 'white' : 'primary'"
            dark
            v-bind="attrs"
            v-on="on"
            icon
            class="menu-dots content-absolute"
          >
            <v-icon :color="dark ? 'white' : 'primary'"
              >mdi-dots-vertical</v-icon
            >
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="modifyContent(content)">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="deleteContent(content)">
            <v-list-item-title>Eliminar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-row>
        <v-col>
          <h3
            class="text-sm-h5 px-5 white-space-break-spaces"
            :class="dark ? 'card-title-dark' : 'card-title'"
          >
            {{ content.title }}
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p
            class="text-sm-subtitle-1 px-5 white-space-break-spaces font-weight-light"
          >
            {{ content.content }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "therapy-card",
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      required: true
    },
    showOptions: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteContent(content) {
      this.$emit("deleteContent", content);
    },
    modifyContent(content) {
      this.$emit("modifyContent", content);
    },
    goToTherapySection() {
      this.$emit("goToTherapySection");
    }
  }
};
</script>

<style scoped>
.card-color {
  background-color: white;
  color: var(--v-primary-base);
}
.card-color-dark {
  background-color: var(--v-primary-base);
  color: white;
}
.card-title {
  border-bottom: solid 3px var(--v-primary-base);
}
.card-title-dark {
  border-bottom: solid 3px white;
}
.align-card-height {
  height: 90%;
  align-self: stretch;
}
.content-absolute {
  position: absolute;
  top: 0;
  right: 0;
}
.content-relative {
  position: relative;
}
.white-space-break-spaces {
  white-space: break-spaces;
}
.card-efect {
  cursor: pointer;
  transition: 0.1s;
}
.card-efect:hover {
  transform: scale(1.05);
}
</style>
