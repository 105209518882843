<template>
  <div>
    <v-card class="fill-height tabs-height" elevation="10">
      <website-section-loader v-if="loading" />
      <error-view v-else-if="errorLoading" />
      <v-tabs
        v-else
        :vertical="$vuetify.breakpoint.lgAndUp"
        center-active
        show-arrows
        class="fill-height tabs-height tabs-background tabs-arrow-prev-background tabs-arrow-next-background"
        dark
      >
        <v-tab
          class="justify-content tabs-shadow-background"
          v-for="(section, index) in sections"
          :key="`${index}-${section.id}`"
        >
          <v-icon left>mdi-web-box</v-icon>
          {{ section.name }}
        </v-tab>
        <v-tab-item
          class="fill-height tabs-height wrapper scroll-style"
          v-for="(section, index) in sections"
          :key="`${index}-${section.id}`"
        >
          <builder-template :section="section">
            <template v-slot:default="websiteContent">
              <website-header
                v-if="section.id === websiteBuilderSections.HEADER.id"
                :content="websiteContent.contents"
                :showOptions="websiteContent.showOptions"
                :previewImage="websiteContent.previewImage"
                :demo="websiteContent.demo"
              />
              <website-footer
                v-if="section.id === websiteBuilderSections.FOOTER.id"
                :content="
                  websiteContent.contents[0] ? websiteContent.contents[0] : {}
                "
                :showOptions="websiteContent.showOptions"
              />
              <therapies-summary
                v-if="
                  section.id === websiteBuilderSections.THERAPIES_SUMMARIES.id
                "
                :content="websiteContent.contents"
                :showOptions="websiteContent.showOptions"
                :demo="websiteContent.demo"
              />
              <info-left-card
                v-if="section.id === websiteBuilderSections.PROFILE_SUMMARY.id"
                :info="
                  websiteContent.contents[0] ? websiteContent.contents[0] : {}
                "
                :colorSet="colorSet.SECONDARY"
                :showOptions="websiteContent.showOptions"
                :previewImage="websiteContent.previewImage"
                :demo="websiteContent.demo"
                onlyCard
              />
              <testimonials
                v-if="section.id === websiteBuilderSections.TESTIMONIALS.id"
                :content="websiteContent.contents"
                :showOptions="websiteContent.showOptions"
                :previewImage="websiteContent.previewImage"
                :demo="websiteContent.demo"
              />
              <info-card
                v-if="section.id === websiteBuilderSections.THERAPIES.id"
                :infoCardsContent="websiteContent.contents"
                :showOptions="websiteContent.showOptions"
                :previewImage="websiteContent.previewImage"
                :demo="websiteContent.demo"
              />
              <info-card
                v-if="section.id === websiteBuilderSections.PROFILE.id"
                :infoCardsContent="websiteContent.contents"
                :showOptions="websiteContent.showOptions"
                :previewImage="websiteContent.previewImage"
                :demo="websiteContent.demo"
              />
              <contact-card
                v-if="section.id === websiteBuilderSections.PROFILE_CARD.id"
                :info="
                  websiteContent.contents[0] ? websiteContent.contents[0] : {}
                "
                :colorSet="colorSet.CLEAN"
                :showOptions="websiteContent.showOptions"
                :previewImage="websiteContent.previewImage"
                :demo="websiteContent.demo"
              />
            </template>
          </builder-template>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { WEBSITE_BUILDER_SECTIONS } from "@/config/constants";
import { WEBSITE_COLOR_SET } from "@/config/constants";
import Repository from "@/services/repositories/repositoryFactory";
const WebsiteRepository = Repository.get("website");
import BuilderTemplate from "./BuilderTemplate";
import Header from "../website/Header";
import Footer from "../website/Footer";
import TherapiesSummary from "../website/TherapiesSummary";
import Testimonials from "../website/Testimonials";
import InfoCardLeft from "../website/InfoCardLeft";
import InfoCard from "../website/InfoCard";
import ContactCard from "../website/ContactCard";
import WebsiteSectionLoader from "@/components/common/loaders/WebsiteSectionLoader";
import ErrorView from "@/components/common/notifications/ErrorView";

export default {
  name: "website-builder",
  components: {
    "website-header": Header,
    "website-footer": Footer,
    "therapies-summary": TherapiesSummary,
    "builder-template": BuilderTemplate,
    "info-left-card": InfoCardLeft,
    testimonials: Testimonials,
    "info-card": InfoCard,
    "contact-card": ContactCard,
    "website-section-loader": WebsiteSectionLoader,
    "error-view": ErrorView
  },
  data() {
    return {
      sections: [],
      colorSet: WEBSITE_COLOR_SET,
      websiteBuilderSections: WEBSITE_BUILDER_SECTIONS,
      loading: false,
      errorLoading: false
    };
  },
  methods: {
    async loadAllSectionsAndContents() {
      this.loading = true;
      this.sections = await WebsiteRepository.getAllSections()
        .catch(() => {
          this.errorLoading = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.loadAllSectionsAndContents();
  }
};
</script>

<style scoped>
.justify-content {
  justify-content: end;
}
.tabs-height {
  height: 100%;
}
.wrapper {
  overflow-y: scroll !important;
  min-height: 833px !important;
  max-height: 833px !important;
}
.scroll-style::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: rgba(168, 168, 168, 0.3);
}
.scroll-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border: solid 1px rgba(255, 255, 255, 0.4);
}
.scroll-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
}
.tabs-background ::v-deep .v-tabs-bar__content {
  color: white !important;
  background: rgb(219, 120, 219);
  background: linear-gradient(
    180deg,
    rgba(219, 120, 219, 1) 12%,
    rgba(205, 140, 236, 1) 100%
  );
}
.tabs-arrow-prev-background ::v-deep .v-slide-group__prev {
  background: linear-gradient(
    180deg,
    rgba(219, 120, 219, 1) 12%,
    rgba(205, 140, 236, 1) 100%
  );
}
.tabs-arrow-next-background ::v-deep .v-slide-group__next {
  background: linear-gradient(
    180deg,
    rgba(219, 120, 219, 1) 12%,
    rgba(205, 140, 236, 1) 100%
  );
}
</style>
